<template>
  <div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <label>Produto:</label>
          <product-selector v-model="filter.standard_for_product" />
        </div>
      </div>
    </div>

    <filter-buttons @restart_filter="restart_filter" @cancel="cancel" @filter="filter_data" />
  </div>
</template>
<script type="text/javascript">
import FilterButtons from '@/theme/buttons/FilterButtons.vue';
import functions from '@/functions';
import ProductSelector from '@/components/Selectors/ProductSelector/ProductSelector.vue';

export default {
  name: 'billing_messagesFilter',
  props: {
    filter_parm: {
      type: Object,
    },
  },
  components: {
    FilterButtons,
    ProductSelector,
  },
  data() {
    return {
      filter: {
        standard_for_product: '',
      },
    };
  },
  methods: {
    set_initial_filter() {
      this.reset_filter_object();
      if (this.filter_parm) this.get_filter_from_parm();
    },
    get_filter_from_parm() {
      const filterKeys = Object.keys(this.filter_parm);
      filterKeys.forEach((key) => {
        this.filter[key] = '';
      });
    },
    reset_filter_object() {
      functions.cleanObjectProperties(this.filter);
    },
    restart_filter() {
      this.reset_filter_object();
      this.filter_data();
    },
    filter_data() {
      this.$emit('filter', this.filter);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    filter_parm: {
      deep: true,
      handler: 'get_filter_from_parm',
    },
  },
  mounted() {
    this.set_initial_filter();
  },
};
</script>
