<template>
  <div>
    <spinner :loading="processing" />
    <content-box title="Mensagens de cobrança" icon="fa-comments-dollar">
      <top-action-buttons @search="MixinFilterBoxToggleFilterBox" @add="newMessage" />
      <toggable-box title="Seleção" size="sm" v-model="mixinFilterBoxOpen">
        <billing-messages-filter
          :filter_parms="messagesFilter"
          @filter="filter_data"
          @cancel="mixinFilterBoxCloseFilter"
        />
      </toggable-box>
      <transition name="slide">
        <div class="update-box" v-if="isUpdateBoxOpen">
          <billing-messages-update @cancel="isUpdateBoxOpen=false"/>
        </div>
      </transition>
      <div class="show_on_pc">
        <table class="table table-striped">
          <thead>
            <th>Título</th>
            <th>Texto</th>
            <th>Produto</th>
            <th>Padrão para</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="message in messages" :key="message.id">
              <td>{{ message.title }}</td>
              <td>{{ message.message }}</td>
              <td>{{ message.product_name }}</td>
              <td>{{ message.standard_for_situation | chargeTab}}</td>
              <td>
                <data-action
                  :actions="data_actions"
                  @edit_record="updateMessage(message.id)"
                  @delete_record="deleteMessage(message.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginator
        :current="messagesFilter.page"
        :pages="messagesFilter.pages"
        @paginate="paginate"
      />
    </content-box>
  </div>
</template>
<script type="text/javascript">
import { mapState, mapActions } from 'vuex';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import Paginator from '@/features/paginator/Paginator.vue';
import Spinner from '@/features/Spinner/Spinner.vue';
import TopActionButtons from '@/theme/buttons/DataListActionButtons.vue';
import ToggableBox from '@/theme/boxes/ToggableBox.vue';
import MixinFilterBox from '@/mixins/FilterBox/FilterBox';
import DataAction from '@/theme/components/DataActions.vue';
import BillingMessagesFilter from './BillingMessagesFilter.vue';
import BillingMessagesUpdate from './BillingMessagesUpdate.vue';

export default {
  components: {
    ContentBox,
    Paginator,
    Spinner,
    TopActionButtons,
    ToggableBox,
    BillingMessagesFilter,
    BillingMessagesUpdate,
    DataAction,
  },
  mixins: [MixinFilterBox],
  data() {
    return {
      processing: false,
      isUpdateBoxOpen: false,
      data_actions: [

        {
          icon: 'fa-edit',
          text: 'Editar mensagem',
          event: 'edit_record',
          color: 'orange',
        },

        {
          icon: 'fa-trash',
          text: 'Apagar',
          event: 'delete_record',
          color: 'red',
        },
      ],
    };
  },
  methods: {
    ...mapActions('billingMessages', [
      'ActionListMessages',
      'ActionListMessagesPaginate',
      'ActionListMessagesApplyFilter',
      'ActionCreateEmptyMessageObject',
      'ActionReadMessage',
      'ActionDeleteMessage',
    ]),
    async paginate(page) {
      try {
        this.processing = true;
        await this.ActionListMessagesPaginate({ page });
      } catch (error) {
        console.log(error);
      } finally {
        this.processing = false;
      }
    },
    async newMessage() {
      await this.ActionCreateEmptyMessageObject();
      this.isUpdateBoxOpen = true;
    },
    async updateMessage(id) {
      await this.ActionReadMessage({ id });
      this.isUpdateBoxOpen = true;
    },
    async deleteMessage(id) {
      this.$dialogbox.confirm('Confirma a exclusão da mensagem?')
        .then(async () => {
          try {
            await this.ActionDeleteMessage({ id });
            await this.ActionListMessages();
            return Promise.resolve();
          } catch (error) {
            return Promise.reject(error);
          }
        });
    },
    async filter_data(payload) {
      try {
        this.processing = true;
        await this.ActionListMessagesApplyFilter({ filter: payload });
      } catch (error) {
        console.log(error);
      } finally {
        this.mixinFilterBoxCloseFilter();
        this.processing = false;
      }
    },

  },
  computed: {
    ...mapState('billingMessages', ['messages', 'messagesFilter']),
  },
  async mounted() {
    try {
      this.processing = true;
      await this.ActionListMessages();
    } catch (error) {
      console.log(error);
    } finally {
      this.processing = false;
    }
  },
};
</script>
<style scoped>
.update-box{
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 13px 5px #f0f0f0;
    margin-bottom: 20px;
}
</style>
