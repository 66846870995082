<template>
    <select v-model="selectedTab" class="form-control">
        <option v-for="(tab, index) in tabs" :key='index' :value='tab.slug'>{{tab.name}}</option>
    </select>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedTab: '',
    };
  },
  computed: {
    ...mapState('charges', ['chargeTabs']),
    tabs() {
      return [
        { slug: '', name: 'Nenhuma' },
        ...this.chargeTabs,
      ];
    },
  },
  watch: {
    value: {
      handler() {
        this.selectedTab = this.value;
      },
    },
    selectedTab: {
      handler() {
        this.$emit('input', this.selectedTab);
      },
    },
  },
  mounted() {
    this.selectedTab = this.value;
  },
};
</script>
