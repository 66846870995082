<template>
  <div class="pad-10">
    <spinner :loading="processing" />
    <div class="row">
      <div class="col-sm-4">
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label for="">Título</label>
              <input type="text" v-model="localTitle" class="form-control" />
              <small>{{ messageErrors.title }}</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label for="">Padrão para a aba</label>
              <charges-tabs-selector v-model="localStandardForSituation" />
              <small>{{ messageErrors.standard_for_situation }}</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label for="">Padrão para o produto</label>
              <product-selector v-model="localStandardForProduct" />
              <small>{{ messageErrors.standard_for_product }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-group">
          <label for="">Mensagem</label>
          <div class="form-row">
            <div class="col">
              <button
                class="btn btn-primary btn-xs btn-add-tag"
                v-for="(field, index) in fieldCodes"
                :key='index'
                @click="insertAtCursor(field.tag)"
              >
                {{field.text}}
              </button>
            </div>
          </div>
          <textarea
            rows="6"
            class="form-control"
            v-model="localMessage"
            id="form_message"
          ></textarea>
          <small>{{ messageErrors.message }}</small>
        </div>
      </div>
    </div>
    <process-cancel-buttons @process="processData" @cancel="cancel" />
  </div>
</template>
<script type="text/javascript">
import { mapActions, mapState, mapMutations } from 'vuex';
import ProcessCancelButtons from '@/theme/buttons/ProcessCancelButtons.vue';
import Spinner from '@/features/Spinner/Spinner.vue';
import ChargesTabsSelector from '@/pages/charges/pages/ChargesTabsSelector.vue';
import ProductSelector from '@/components/Selectors/ProductSelector/ProductSelector.vue';

export default {
  components: {
    ProcessCancelButtons,
    Spinner,
    ChargesTabsSelector,
    ProductSelector,
  },
  data() {
    return {
      processing: false,
      fieldCodes: [
        { slug: 'nome_cliente', tag: '##nomecliente##', text: 'Nome do cliente' },
        { slug: 'link_boleto', tag: '##linkboleto##', text: 'Link para cobrança' },
        { slug: 'cod_barras', tag: '##codbarras##', text: 'Código de barras' },
        { slug: 'num_pedido', tag: '##numpedido##', text: 'Número do pedido' },
        { slug: 'email_cliente', tag: '##emailcliente##', text: 'E-mail do cliente' },
      ],
    };
  },
  computed: {
    ...mapState('billingMessages', ['message', 'messageErrors']),
    localTitle: {
      get() {
        return this.message.title;
      },
      set(value) {
        this.SET_MESSAGE_PROPERTY({ property: 'title', content: value });
      },
    },
    localStandardForProduct: {
      get() {
        return this.message.standard_for_product;
      },
      set(value) {
        this.SET_MESSAGE_PROPERTY({ property: 'standard_for_product', content: value });
      },
    },
    localStandardForSituation: {
      get() {
        return this.message.standard_for_situation;
      },
      set(value) {
        this.SET_MESSAGE_PROPERTY({ property: 'standard_for_situation', content: value });
      },
    },
    localMessage: {
      get() {
        return this.message.message;
      },
      set(value) {
        this.SET_MESSAGE_PROPERTY({ property: 'message', content: value });
      },
    },
  },
  methods: {
    ...mapActions('billingMessages', [
      'ActionCreateMessage',
      'ActionUpdateMessage',
    ]),
    ...mapMutations('billingMessages', [
      'SET_MESSAGE_PROPERTY',
    ]),
    cancel() {
      this.$emit('cancel');
    },
    localMessageObject() {
      return {
        ...this.message,
        title: this.localTitle,
        message: this.localMessage,
        standard_for_situation: this.localStandardForSituation || '',
        standard_for_product: this.localStandardForProduct || 0,
      };
    },
    async processData() {
      try {
        if (this.message.id) {
          await this.ActionUpdateMessage({
            id: this.message.id,
            data: this.localMessageObject(),
          });
        } else {
          await this.ActionCreateMessage({ data: this.localMessageObject() });
        }
        this.cancel();
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    insertAtCursor(tagToBeInserted) {
      const messageField = document.getElementById('form_message');

      if (document.selection) {
        messageField.focus();
        const sel = document.selection.createRange();
        sel.text = tagToBeInserted;
      } else if (messageField.selectionStart || messageField.selectionStart === 0) {
        const startPos = messageField.selectionStart;
        const endPos = messageField.selectionEnd;
        messageField.value = messageField.value.substring(0, startPos)
          + tagToBeInserted
          + messageField.value.substring(endPos, messageField.value.length);
      } else {
        messageField.value += tagToBeInserted;
      }

      this.$set(this.message, 'message', messageField.value);
    },
  },
};
</script>
<style type="text/css">
.btn-add-tag{
  margin-left:0px;
  margin-right:10px;
  margin-bottom:10px;
}
</style>
