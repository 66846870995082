export default {
  data() {
    return {
      mixinFilterBoxOpen: false,
    };
  },
  methods: {
    MixinFilterBoxToggleFilterBox() {
      this.mixinFilterBoxOpen = !this.mixinFilterBoxOpen;
    },
    mixinFilterBoxCloseFilter() {
      this.mixinFilterBoxOpen = false;
    },
  },
};
